import thumb from "../../images/thumbs-up.png"
import clock from "../../images/clock-check.png"
import user from "../../images/users-check.png"
import messg from "../../images/message-chat-circle.png"

export const HighlightData = [
    {
        id : 1,
        name : "Quality Materials",
        img : thumb,
        para : "It is a long established fact that a reader will be distracted.",
    },
    {
        id : 2,
        name : "Professional Expert",
        img : clock,
        para : "It is a long established fact that a reader will be distracted.",
    },
    {
        id : 3,
        name : "On time Finish Works",
        img : user,
        para : "It is a long established fact that a reader will be distracted.",
    },
    {
        id : 4,
        name : "24/7 Premium Support",
        img : messg,
        para : "It is a long established fact that a reader will be distracted.",
    },
]


