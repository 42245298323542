import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import BlogListComponent from "../../Components/BlogComponent/BlogListComponent";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const BlogList = () => {
  useDocumentTitle("Roofing | Blogs Lists")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <BlogListComponent />
      <ContactComponent/>
      <Footer />
    </div>
  );
};

export default BlogList;
