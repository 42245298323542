import React from 'react'
import "./Highlight.css"
import redCross from "../../images/red-crose.png"
import whiteCros from "../../images/white-crose.png"
import fff from "../../images/fff.png"
import brand1 from "../../images/Brand 1.png"
import brand2 from "../../images/Brand 2.png"
import brand3 from "../../images/Brand 3.png"
import brand4 from "../../images/Brand 4.png"
import brand5 from "../../images/Barand 6.png"
import brand6 from "../../images/Brand 6.png"
import brand7 from "../../images/Brand 7.png"



const Highlight = () => {
  return (
    <section className="troo-da-roofing-trusted" id="troo-da-roofing-trusted">
    <div className="cros-css"><img src={redCross} alt='redCross' /></div>
    <div className="white-croe"><img src={whiteCros} alt='whiteCros' /></div>
    <div className="slide">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="trust-text">
              <h3>Trusted by 12051+ World-className Brands and Organizations of All Sizes</h3>
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                alteration
                in some form. Lorem Ipsum available, but the majority have suffered alteration in some form. Lorem Ipsum
                available.</p>
              <div className="zigzag-img"><img src={fff} alt='fff' /></div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="logo-section">
              <div className="row">
                <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand1} alt='brand1' /></div>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand2} alt='brand2' /></div>
                </div>
                <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand3} alt='brand3' /></div>
                </div>
                <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand4} alt='brand4' /></div>
                </div>
                <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand6} alt='brand6' /></div>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand7} alt='brand7' /></div>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="logo-img"><img src={brand5} alt='brand5' /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Highlight