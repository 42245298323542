import React from 'react'
import "./Banner.css"
import { Link } from 'react-router-dom'
import { BiPlus } from 'react-icons/bi';

const Banner = () => {
  return (
    <section className="troo-da-hero-section" id="troo-da-hero-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="banner-text">
            <div className="white-line"></div>
            <h1>We Build Your Roof With
              Modern Technology</h1>
            <h4>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
              alteration in some form.</h4>
            <ul>
              <li><Link href="#" className="custom-btn btn-13"><BiPlus className='plus'/> Learn More</Link>
              </li>
              <li> <Link href="#" className="custom-btn btn-14"><BiPlus className='plus'/> View Services</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Banner