import React from 'react'
import "./BlogComponent.css"
import roof from "../../images/roof-blog.png"
import subtitle from "../../images/subtile-icon.png"
import blog1 from "../../images/blog.png"
import blog2 from "../../images/blog-2.png"
import { BiPlus } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const BlogComponent = () => {
  return (
    <section className="troo-da-blog" id="troo-da-blog">
    <div className="container">
      <div className="blog-roof"><img src={roof} alt='roof' /></div>
      <div className="row">
        <div className="col-xl-7 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="blog-cricle"><span></span> <span className="spiner"></span></div>
          <div className="sub-tile">
            <p><img src={subtitle} alt='subtitle' />News and Blogs</p>
            <h2>Stay Update with TRooRoofing News & Blogs
            </h2>
          </div>

        </div>
        <div className="col-xl-5 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="blog-btm">
            <Link href="#" className="custom-btn btn-13"><BiPlus className='plus'/>See All Feedback</Link>
          </div>

        </div>
      </div>
      <div className="row">
        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="blog-section">
            <div className="blog-img">
              <div className="date-col"><span>22, Dec, 2023</span></div>
              <img src={blog1} alt='blog1' />
              <div className="blog-tile">
                <span>Admin / Home Roofing</span>
                <h2>Roof Repairs Is Standard Part of Our
                  Dedicated Work</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="blog-list">
            <div className="sub-blog">
              <div className="subblog-img">
                <div className="date-col"><span>22, Dec, 2023</span></div>
                <img src={blog2} alt='blog2' />
              </div>
              <div className="sib-blog-tex">
                <span> Admin / Home Roofing</span>
                <h3>Roof worker treak truned into
                  your roof a new type roof</h3>
                <Link href="#">Read Full Article</Link>
              </div>
            </div>
            <div className="sub-blog">
              <div className="subblog-img">
                <div className="date-col"><span>22, Dec, 2023</span></div>
                <img src={blog2} alt='blog3' />
              </div>
              <div className="sib-blog-tex">
                <span> Admin / Home Roofing</span>
                <h3>Roof worker treak truned into
                  your roof a new type roof</h3>
                <Link href="#">Read Full Article</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BlogComponent