import React from "react";
import "./ProjectList.css";
import { ProjectData } from "./ProjectData";
import { Link, createSearchParams } from "react-router-dom";

const ProjectListComponent = () => {
  return (
    <section
      className="troo-da-project-list-section"
      id="troo-da-project-list-section"
    >
      <div className="container">
        <div className="row">
          {ProjectData.map((e, i) => (
            <div
              className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12"
              key={i}
            >
              <Link to={`/our-works/works-details?${createSearchParams({id:e.id})}`}>
                <div className="project-list">
                  <img src={e.img2} alt="building" />
                  <div className="pluse-icon">+</div>
                  <div className="project-list-title">
                    <span>{e.para}</span>
                    <h5>{e.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectListComponent;
