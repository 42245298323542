import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQComponent2 = () => {
  return (
    <section className="troo-da-faq-section" id="troo-da-faq-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <Accordion defaultActiveKey="0">
              <h4>General Questions</h4>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  5. What are the different types of asphalt shingles?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. How long will it take to replace my roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <Accordion defaultActiveKey="0">
              <h4>Payment Related Questions</h4>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  5. What are the different types of asphalt shingles?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. How long will it take to replace my roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <Accordion defaultActiveKey="0">
              <h4>Account Related Questions</h4>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  5. What are the different types of asphalt shingles?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. How long will it take to replace my roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <Accordion defaultActiveKey="0">
              <h4>Other Questions</h4>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  5. What are the different types of asphalt shingles?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. How long will it take to replace my roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQComponent2;
