import React from "react";
import "./ProjectComponent.css";
import roof from "../../images/roof.png";
import circle from "../../images/circles.png";
import subtitle from "../../images/subtile-icon.png";
import roofs from "../../images/roofs.png";
import { BiPlus } from "react-icons/bi";
import { Link, createSearchParams } from "react-router-dom";
import { ProjectData } from "./ProjectData";

const ProjectComponent = () => {
  return (
    <section
      className="troo-da-roofing-latest-projects"
      id="troo-da-roofing-latest-projects"
    >
      <div className="roof">
        <img src={roof} alt="roof" />
      </div>
      <div className="container">
        <div className="pro-cc">
          <img src={circle} alt="circle" />
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="sub-tile">
              <p>
                <img src={subtitle} alt="subtitle" />
                Our Latest Projects
              </p>
              <h2>Quality Workmanship Done Right The First Time.</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {ProjectData.slice(0,7).map((e, i) => (
            <div
              className="col-xl-3 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12"
              key={i}
            >
              <Link to={`/our-works/works-details?${createSearchParams({id:e.id})}`}>
                <div className="project">
                  <div className="project-img">
                    <img src={e.img} alt="project1" />
                  </div>
                  <div className="pro-plus">+</div>
                  <div className="project-title">
                    <div className="project-pluse">+</div>
                    <span>{e.para}</span>
                    <h3>{e.name} </h3>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          <div className="col-xl-3 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="all-project">
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered .
              </p>
              <Link to="#" className="custom-btn btn-14">
                <BiPlus className="plus" /> View Services
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="loader-ss">
        <span></span>
        <span></span>
      </div>
      <div className="roofs">
        <img src={roofs} alt="roofs" />
      </div>
    </section>
  );
};

export default ProjectComponent;
