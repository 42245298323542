import React from "react";
import contact from "../../images/contact.png";

const ContactComponent2 = () => {
  return (
    <>
      <section className="troo-da-contact" id="troo-da-contact">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          title="gmap"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="contact-ss">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="contactimg">
                  <img src={contact} alt="contact" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12 col-xs-12">
                <div className="contact-text">
                  <form className="row g-3 form-section">
                    <span>Write Us Message</span>
                    <h4>Get In Touch</h4>
                    <div className="col-md-12">
                      <div className="form-col">
                        <label for="inputName" className="form-label">
                          Name
                        </label>
                        <input
                          type="name"
                          className="form-control"
                          id="inputName"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-col">
                        <label for="inputPhone" className="form-label">
                          Phone
                        </label>
                        <input
                          type="phone"
                          className="form-control"
                          id="inputphone"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-col">
                        <label for="inputEmail" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-col">
                        <label for="inputState" className="form-label">
                          Subject
                        </label>
                        <select id="inputState" className="form-select">
                          <option selected>Subject...</option>
                          <option>...</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-col">
                        <label for="inputZip" className="form-label">
                          How can we help you?
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="custom-btn btn-13">
                        {" "}
                        <i className="fa fa-plus" aria-hidden="true"></i> Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="troo-da-contact-deails" id="troo-da-contact-deails">
        <div className="container">
          <div className="row">
            <div className="contat-con">
              <h2>Do you have any questions? Contact Us!</h2>
              <ul>
                <li>
                  <a href="">+44 123 456 7890</a>
                </li>
                <li>
                  <a href="">info@troothemes.com</a>
                </li>
              </ul>
              <p>
                073 Briarwood Drive, Hopewell, New Jersey, 08525, United States
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactComponent2;
