export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "Who We Are",
  },
  {
    title: "Our Service",
    path: "#",
    subNav: [
      {
        subTitle: "our services",
        heading: "What We Provide",
        path: "/our-services",
      },

      {
        subTitle: "our service lists",
        heading: "What We Provide",
        path: "/our-service-lists",
      },

      {
        subTitle: "service details",
        heading: "Roof Installation Service",
        path: "/our-services/service-details",
      },
    ],
  },
  {
    title: "Our Works",
    path: "#",
    subNav: [
      {
        subTitle: "our works",
        heading: "Recently We Done Works",
        path: "/our-works",
      },

      {
        subTitle: "our works2",
        heading: "Recently We Done Works",
        path: "/our-works2",
      },

      {
        subTitle: "works details",
        heading: "Modern Pattern Stylee",
        path: "/our-works/works-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "our team",
        heading: "Meet Our Team Member",
        path: "/our-team",
      },
      {
        subTitle: "team details",
        heading: "William Regle",
        path: "/our-team/team-details",
      },
      {
        subTitle: "testimonail",
        heading: "Our Clients Review",
        path: "/testimonail",
      },

      {
        subTitle: "faq",
        heading: "Frequently Asked Questions",
        path: "/faq",
      },
      {
        subTitle: "pricing table",
        heading: "Our Best Plans For You",
        path: "/pricing-table",
      },
      {
        subTitle: "error 404",
        heading: "404 Error",
        path: "/error-404",
      },
      {
        subTitle: "comming soon",
        heading: "Comming Soon",
        path: "/comming-soon",
      },
    ],
  },
  {
    title: "Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "blogs",
        heading: "Our News and Article",
        path: "/blogs",
      },
      {
        subTitle: "blogs lists",
        heading: "Our News and Article List",
        path: "/blogs-lists",
      },
      {
        subTitle: "blog details",
        heading: "Keep Your Home Cool Comfortable This Summer",
        path: "/blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "Let’s Talk With Us",
  },
  {
    title: "get a quote",
    heading: "Get A Quote",
    path: "/get-a-quote",
  },
];
