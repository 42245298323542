import React, { useState } from "react";
import "./TeamComponent.css";
import paly from "../../images/play-circle.png";
import tryimg from "../../images/trg.png";
import subtitle from "../../images/subtile-icon.png";
import pluse from "../../images/pluse-Icon.png";
import icon from "../../images/Icon.png";
import zigcircle from "../../images/zigcircle.png";
import { BiPlus } from "react-icons/bi";
import { TeamData } from "./TeamData";
import { Link, createSearchParams } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";

const TeamComponent = () => {
  const [modal, setModal] = useState(false);
  const openModal = () => {
    if (!modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    setModal(!modal);
  };
  return (
    <section className="troo-da-experts-team" id="troo-da-experts-team">
      <a data-bs-toggle="modal" href="#myModal" className="video-btn">
        <div className="video-model-tile">
          <div className="video-tile"> We Provie Best Roofing Services</div>
          <div className="video-img pulse" onClick={openModal}>
            <img src={paly} alt="paly" />
            {modal ? (
                <section className="modal__bg">
                  <div className="modal__align">
                    <div className="modal__content" modal={modal}>
                      <IoCloseOutline
                        className="modal__close"
                        arial-label="Close modal"
                        onClick={setModal}
                      />
                      <div className="modal__video-align">
                        <iframe
                          className="modal__video-style"
                          width="800"
                          height="500"
                          src="https://www.youtube.com/embed/pXxsZtcbKdw?start=96"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
          </div>
        </div>
      </a>
      <div className="container">
        <div className="trg-img">
          <img src={tryimg} alt="tryimg" />
        </div>
        <div className="expert-section">
          <div className="sub-tile">
            <p>
              <img src={subtitle} alt="subtitle" />
              Our Experts Team
            </p>
            <h2>We Have Experts Team at Fixing Your roofs.</h2>
          </div>
        </div>
        <div className="team-section">
          <div className="row">
            {TeamData.slice(0,3).map((e, i) => (
              <div
                className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12"
                key={i}
              >
             <Link to={`/our-team/team-details?${createSearchParams({id:e.id})}`}>
             <div className="expert-team">
                  <div className="exprt-main-img">
                    <img src={e.img} alt="computer" />
                  </div>
                  <div className="social-icon">
                    <div className="plu">
                      <img src={pluse} alt="pluse" />
                      <ul>
                        {e.social.map((icn, i) => (
                          <li key={i}>
                            <Link to={icn.path}>{icn.icon}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="expert-dec">
                    <div className="expert-text">
                      <span>{e.para}</span>
                      <h3>{e.name}</h3>
                    </div>
                    <div className="expert-icon">
                      <img src={icon} alt="icon" />
                    </div>
                  </div>
                </div>
             </Link>
              </div>
            ))}
          </div>
          <div className="dotted">
            <img src={zigcircle} alt="zigcircle" />
          </div>
        </div>
        <div className="allexerp-bnt">
          <Link href="#" className="custom-btn btn-13">
            <BiPlus className="plus" />
            View All Member
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TeamComponent;
