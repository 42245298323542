import blog1 from "../../images/blog-1.png";
import blog2 from "../../images/blog2.png";
import blog3 from "../../images/blog-3.png";
import blog4 from "../../images/blog-4.png";
import blog5 from "../../images/blog-5.png";
import blog6 from "../../images/blog-6.png";
import blog7 from "../../images/blog-7.png";
import blog8 from "../../images/blog-8.png";
import blog9 from "../../images/blog-9.png";
import blogList1 from "../../images/blog-list1.png"
import blogList2 from "../../images/blog-list2.png"
import blogList3 from "../../images/blog-list3.png"
import blogList4 from "../../images/blog-list4.png"
import blogList5 from "../../images/blog-list5.png"
import blogList6 from "../../images/blog-list6.png"

export const BlogData = [
  {
    id: 1,
    img: blog1,
    name: "Roof Repairs Is Standard Part of Our Dedicated Work",
    sub: "Admin / Home Roofing",
    img2: blogList1,
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 2,
    img: blog2,
    img2: blogList2,
    name: "10 Efficient & Measurable Benefits Of Software",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 3,
    img: blog3,
    img2: blogList3,
    name: "Vital Tips For Blockchain Software Product.",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 4,
    img: blog4,
    img2: blogList4,
    name: "Native Cross-Platform: Mobile Development.",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 5,
    img: blog5,
    img2: blogList5,
    name: "Ex-homeless shelter head agrees to settlement",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 6,
    img: blog6,
    img2: blogList6,
    name: "Protect your roof with 4 winter mistakes",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 7,
    img: blog7,
    name: "Roofing surveys paints optimistic  outlook",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 8,
    img: blog8,
    name: "Roofing Surveys Paints Optimistic  Cutlook",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },
  {
    id: 9,
    img: blog9,
    name: "Vital Tips For Blockchain Software Product.",
    sub: "Admin / Home Roofing",
    date: "22, Dec, 2023",
    para : "Lorem ipsum dolor sit amet consec tetur adipisicing sed do eiusmod tempor incid idunt labore"
  },

];
