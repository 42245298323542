import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import ProjectComponent from '../../Components/ProjectComponent/ProjectComponent'
import useDocumentTitle from '../../PageTitle'

const OurWOrks = () => {
  useDocumentTitle("Roofing | OurWorks")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <ProjectComponent/>

        <Footer/>
    </div>
  )
}

export default OurWOrks