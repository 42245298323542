import React from "react";
import "./AboutComponent.css";
import subtitle from "../../images/subtile-icon.png";
import rightIcon from "../../images/right-icon.png";
import abtPng from "../../images/aut.png";
import grass from "../../images/grass-architecture.png";
import redSot from "../../images/red-sot.png";
import aboutPng from "../../images/about.png";
import hex from "../../images/hex.png";
import { BiPlus } from 'react-icons/bi';
import { Link } from "react-router-dom";

const AboutComponent = () => {
  return (
    <section className="troo-da-about-us-section" id="troo-da-about-us-section">
      <div className="container">
        <div className="about-text">
          <div className="row">
            <div className="col-md-6 slide-left">
              <div className="about-text">
                <div className="sub-tile">
                  <p>
                    <img src={subtitle} alt="subtitle" />
                    Get to Know About Us
                  </p>
                  <h2>
                    Best Choice For Your Commercial Residential
                    <span> Roof Installation </span> Projects
                  </h2>
                </div>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
                <ul>
                  <li>
                    <img src={rightIcon} alt="rightIcon" />{" "}
                    <span>
                      Emergency response time is one hour or less guaranteed.
                    </span>
                  </li>
                  <li>
                    <img src={rightIcon} alt="rightIcon" />{" "}
                    <span>
                      Until the eleifend elit is a lot of great facilities, what
                      is the price of the pain
                    </span>
                  </li>
                  <li>
                    <img src={rightIcon} alt="rightIcon" />{" "}
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </span>
                  </li>
                  <li>
                    <img src={rightIcon} alt="rightIcon" />{" "}
                    <span>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </span>
                  </li>
                </ul>
                <div className="best-chice-cta">
                  <div className="best-ss">
                    <Link href="#" className="custom-btn btn-13">
                     <BiPlus className='plus'/>
                      Learn More
                    </Link>
                  </div>
                  <div className="auther-col">
                    <div className="auth-img">
                      <img src={abtPng} alt="abtPng" />
                    </div>
                    <div className="auther-text">
                      <strong>William Boroman</strong>
                      <span>CEO and Founder</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 slide-rights">
              <div className="about-img-col">
                <div className="row">
                  <div className="col-md-6">
                    <div className="grass-img">
                      <img src={grass} alt="grass" />
                    </div>
                    <div className="red-col">
                      <span>Established & Serving Since</span>
                      <h6>1980</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="right-img-col">
                      <div className="dot">
                        <img src={redSot} alt="redSot" />
                      </div>
                      <div className="grass-img">
                        <img src={aboutPng} alt="aboutPng" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="water-round-container">
          <div className="water-wave1">
            <svg
              width="24"
              height="85"
              viewBox="0 0 24 85"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.09 0C23.09 4.68 18.09 4.68 18.09 9.36C18.09 14.04 23.09 14.04 23.09 18.72C23.09 23.4 18.09 23.4 18.09 28.07C18.09 32.74 23.09 32.75 23.09 37.43C23.09 42.11 18.09 42.11 18.09 46.79C18.09 51.47 23.09 51.47 23.09 56.15C23.09 60.83 18.09 60.83 18.09 65.51C18.09 70.19 23.09 70.19 23.09 74.88C23.09 79.57 18.09 79.56 18.09 84.24"
                stroke="#E22526"
                stroke-miterlimit="10"
              />
              <path
                d="M14.57 0C14.57 4.68 9.52002 4.68 9.52002 9.36C9.52002 14.04 14.57 14.04 14.57 18.72C14.57 23.4 9.52002 23.4 9.52002 28.07C9.52002 32.74 14.57 32.75 14.57 37.43C14.57 42.11 9.52002 42.11 9.52002 46.79C9.52002 51.47 14.57 51.47 14.57 56.15C14.57 60.83 9.52002 60.83 9.52002 65.51C9.52002 70.19 14.57 70.19 14.57 74.88C14.57 79.57 9.52002 79.56 9.52002 84.24"
                stroke="#E22526"
                stroke-miterlimit="10"
              />
              <path
                d="M6.05 0C6.05 4.68 1 4.68 1 9.36C1 14.04 6.05 14.04 6.05 18.72C6.05 23.4 1 23.4 1 28.07C1 32.74 6.05 32.75 6.05 37.43C6.05 42.11 1 42.11 1 46.79C1 51.47 6.05 51.47 6.05 56.15C6.05 60.83 1 60.83 1 65.51C1 70.19 6.05 70.19 6.05 74.88C6.05 79.57 1 79.56 1 84.24"
                stroke="#E22526"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
        </div>
        <div className="cube">
          <img src={hex} alt="hex" />
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
