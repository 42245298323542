import React from 'react'
import "./ClientFeedback.css"
import { ClientData } from './ClientData';


const ClientFeedback2 = () => {
  return (
    <section className="troo-da-team-deatils-section" id="troo-da--deatils-section">
    <div className="container">
    <div className="row">
          {ClientData.map((e, i) => (
            <div
              className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12"
              key={i}
            >
              <div className="client-wapper">
                <div className="client-img">
                  <img src={e.img} alt="laxy" />
                  <div className="clientqute">
                    <p>”</p>
                  </div>
                </div>
                <div className="client-deails">
                  <div className="clit-reviw">
                    <div className="client-tile">
                      <h5>{e.name}</h5>
                      <span>{e.desg}</span>
                    </div>
                    <div className="start-img">{e.star}</div>
                  </div>
                  <p>{e.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
    </div>
  </section>
  )
}

export default ClientFeedback2