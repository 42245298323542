import React from 'react'
import "./Highlight.css"
import subtitle from "../../images/subtile-icon.png"
import right from "../../images/right-icon.png"
import { BiPlus } from 'react-icons/bi';
import { HighlightData } from './HighlightData';
import { Link } from 'react-router-dom';

const Highlights2 = () => {
  return (
    <section class="troo-da-why-choose slideInDown" id="troo-da-why-choose">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div class="chose-text">
            <div class="sub-tile">
              <p><img src={subtitle} alt='subtitle' />Why Choose Us</p>
              <h2>Many Reasons For Why People Choosing</h2>
            </div>
            <strong>It is a long established fact that a reader will be distracted by the readable content of a page
              when looking at its layout. The point of using reader will be distracted
            </strong>
            <ul>
              <li><img src={right} alt='right' />
                <span>Emergency response time is one hour or less guaranteed.</span>
              </li>
              <li><img src={right} alt='right' />
                <span>Until the eleifend elit is a lot of great facilities, what is the price of the pain</span>
              </li>
              <li><img src={right} alt='right' />
                <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
              </li>
            </ul>
            <Link href="#" class="custom-btn btn-13"><BiPlus className='plus'/> Make An Appointment</Link>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div class="choose-ss">
            <div class="row">
              {
                HighlightData.map((e,i)=>
                <div class="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12" key={i}>
                <div class="choose-card">
                  <div class="chose-img"><img src={e.img} alt='thumb'  /></div>
                  <div class="chose-txt">
                    <h4>{e.name} </h4>
                    <p>{e.para} </p>
                  </div>
                </div>
              </div>
                )
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Highlights2