import React from "react";
import subtitle from "../../images/subtile-icon.png";
import trangle from "../../images/triangle.png";
import architecture from "../../images/architecture-structure.png";
import { BiPlus } from "react-icons/bi";
import { ServiceData } from "./ServiceData";
import { Link, createSearchParams } from "react-router-dom";

const ServiceListComponent = () => {
  return (
    <section className="troo-da-roofing-services" id="troo-da-roofing-services">
      <div className="container">
        <div className="cricless">
          <div className="center">
            <div id="ball"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="sub-tile">
              <p>
                <img src={subtitle} alt="subtitle" />
                We Are Roofing Expert
              </p>
              <h2>
                We Provide All Types of
                <span>Roofing Services </span> for Every Need
              </h2>
            </div>
          </div>
        </div>
        <div className="row row-gap-5">
          {ServiceData.map((e, i) => (
            <div className=" d-flex flex-md-row gap-4 flex-column" key={i}>
              <div className="service-list col-xl-6 col-lg-6  col-md-6  col-12">
                <img src={e.img2} alt="service1" />
              </div>
              <div className="servic-text col-xl-6 col-lg-6 col-md-6 col-12">
                <h4>{e.name} </h4>
                <p>{e.para2} </p>
                <Link to={`/our-services/service-details?${createSearchParams({id:e.id})}`} className="custom-btn btn-14">
                  <BiPlus className="plus" />
                  View Full Detail
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="triangle">
        <img src={trangle} alt="trangle" />
      </div>
      <div className="right-img">
        <div className="slide-right">
          <img src={architecture} alt="architecture" />
        </div>
      </div>
    </section>
  );
};

export default ServiceListComponent;
