import React, { useEffect, useState } from 'react'
import Accordion from "react-bootstrap/Accordion";
import detailsImg from "../../images/landscape-architecture-villa.png"
import rightIcon from "../../images/right-icon.png"
import detailsimg2 from "../../images/landscape-architecture-villa-house-interior.png"
import detailsimg3 from "../../images/landscape-architecture-villa-house-building.png"
import { FaAngleRight } from 'react-icons/fa';
import call from "../../images/red-callIcon.png"
import { BiPlus } from 'react-icons/bi'
import { Link, useSearchParams } from 'react-router-dom';
import { ProjectData } from './ProjectData';

const ProjectDetailsComponent = () => {
  const [searchParams] = useSearchParams();
  const [photo, setPhoto] = useState();
  const [heading, setHeading] = useState();
  const id = searchParams.get("id");
  console.log(id);
  useEffect(() => {
    const filData = ProjectData.find((data) => data?.id == id);
    console.log(filData);
    setPhoto(filData?.img2);
    setHeading(filData?.name);
  }, [id]);
  return (
    <section className="troo-da-services-detail-section" id="troo-da-services-detail-section">
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-8 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="services-detail-section">
            <div className="services-detail-img">
              <img src={ photo || detailsImg} alt='detailsImg' />
            </div>
            <div className="services-detail-text">
              <h2>{heading || "We Ensure Best Service"}</h2>
              <p>Lorem Ipsum Doller sit amet sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec
                sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend,
                ex justo aliquam nunc, in ultrices ante quam eget massa. Sed and scelerisque, odio eu tempor pulvinar,
                magna tortor finibus lorem, ut mattis tellus nunc ut quam. Curabitur quis ornare leo. Suspendisse
                bibendum nibh.</p>
              <ul>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>Emergency response time is one hour or less guaranteed.</span>
                </li>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>Until the eleifend elit is a lot of great facilities, what is the price of the pain</span>
                </li>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </li>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    alteration in some form, by injected humour
                  </span>
                </li>
              </ul>
              <p>Lorem Ipsum Doller sit amet sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec
                sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend,
                ex justo aliquam nunc, in ultrices ante quam eget massa. Sed and scelerisque.</p>
            </div>
          </div>
          <div className="sub-ser-img">
            <div className="row">

              <div className="col-xl-6 col-lg-6  mb-4 col-md-6 col-sm-12 col-xs-12">
                <img width="100%" src={detailsimg2} alt='detailsimg2' />
              </div>
              <div className="col-xl-6 col-lg-6  mb-4 col-md-6 col-sm-12 col-xs-12">
                <img width="100%" src={detailsimg3} alt='detailsimg3' />
              </div>
            </div>
          </div>
          <h2>What Is Included</h2>
          <div className="servci-accordiund">
          <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4  mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="sevice-details-slidebar">
            <ul>
              <li><Link href="#">
                  <span>Single Play Roofing </span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#">
                  <span> Modified Roofing</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link></li>
              <li>
                <Link href="#">
                  <span> Built-up Roofing </span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#"> <span>Roof Inspection</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#"> <span>Roof Inspection</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#"> <span>Metal Roofing</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link></li>
              <li><Link href="#"> <span>Other Services</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
            </ul>

            <div className="red-call">
              <div className="pulse"><img src={call} alt='call' /></div>
              <div className="red-call-text">
                <h4>Have any Questions?
                  Call us Today!</h4>
                <Link href="#">+44 123 456 7890</Link>
                <Link href="#">+44 987 654 3210</Link>
              </div>
            </div>
            <div className="brochures-col">
              <h4>Brochures</h4>
              <p>Lorem ipsum dolor sit amet, simply it
                sign adipisicing elit.</p>
              <Link href="#" className="custom-btn btn-14"><BiPlus className='plus'/> Download</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ProjectDetailsComponent