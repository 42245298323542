import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import ServiceListComponent from "../../Components/ServiceComponent/ServiceListComponent";
import Highlight from "../../Components/Highlights/Highlight";
import ProjectComponent from "../../Components/ProjectComponent/ProjectComponent";
import Highlights2 from "../../Components/Highlights/Highlights2";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const ServiceList = () => {
  useDocumentTitle("Roofing | Service Lists")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ServiceListComponent />
      <Highlight />
      <ProjectComponent />
      <Highlights2 />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default ServiceList;
