import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import About from "./Routes/About";
import Services from "./Routes/Service/Services";
import ServiceList from "./Routes/Service/ServiceList";
import ServiceDetails from "./Routes/Service/ServiceDetails";
import OurWOrks from "./Routes/Works/OurWOrks";
import WOrkList from "./Routes/Works/WOrkList";
import WorkDetails from "./Routes/Works/WorkDetails";
import Teams from "./Routes/Pages/Teams";
import TeamDetails from "./Routes/Pages/TeamDetails";
import Testimonials from "./Routes/Pages/Testimonials";
import FAQs from "./Routes/Pages/FAQs";
import PricingTable from "./Routes/Pages/PricingTable";
import Error404 from "./Routes/Pages/Error404";
import ComingSoon from "./Routes/Pages/ComingSoon";
import Blogs from "./Routes/Blogs/Blogs";
import BlogList from "./Routes/Blogs/BlogList";
import BlogDetails from "./Routes/Blogs/BlogDetails";
import Contacts from "./Routes/Contacts";
import Quotes from "./Routes/Quotes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />

          <Route path="/our-services" element={<Services />} />
          <Route path="/our-service-lists" element={<ServiceList />} />
          <Route path="/our-services/service-details" element={<ServiceDetails />}/>

          <Route path="/our-works" element={<OurWOrks />} />
          <Route path="/our-works2" element={<WOrkList />} />
          <Route path="/our-works/works-details" element={<WorkDetails />} />

          <Route path="/our-team" element={<Teams />} />
          <Route path="/our-team/team-details" element={<TeamDetails />} />
          <Route path="/testimonail" element={<Testimonials />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/pricing-table" element={<PricingTable />} />
          <Route path="/error-404" element={<Error404 />} />
          <Route path="/comming-soon" element={<ComingSoon />} />

          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs-lists" element={<BlogList />} />
          <Route path="/blogs/blog-details" element={<BlogDetails />} />

          <Route path="/contact-us" element={<Contacts />} />
          <Route path="/get-a-quote" element={<Quotes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
