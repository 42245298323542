import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ComingSoonComponent from "../../Components/ComingSoonComponent/ComingSoonComponent";
import useDocumentTitle from "../../PageTitle";

const ComingSoon = () => {
  useDocumentTitle("Roofing | Coming Soon")
  return (
    <div>
      <Header />
      <ComingSoonComponent />
      <Footer />
    </div>
  );
};

export default ComingSoon;
