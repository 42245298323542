import React from 'react'
import Header from '../Components/Header/Header'
import Banner from '../Components/Banner/Banner'
import Footer from '../Components/Footer/Footer'
import AboutComponent from '../Components/AboutComponent/AboutComponent'
import Counter from '../Components/Counter/Counter'
import ServiceComponent from '../Components/ServiceComponent/ServiceComponent'
import Highlight from '../Components/Highlights/Highlight'
import ProjectComponent from '../Components/ProjectComponent/ProjectComponent'
import Highlights2 from '../Components/Highlights/Highlights2'
import TeamComponent from '../Components/TeamComponent/TeamComponent'
import ClientFeedback from '../Components/ClientFeedback/ClientFeedback'
import ServiceProvider from '../Components/ServiceProvider/ServiceProvider'
import FAQComponent from '../Components/FAQComponent/FAQComponent'
import PricingTableComponent from '../Components/PricingTableComponent/PricingTableComponent'
import BlogComponent from '../Components/BlogComponent/BlogComponent'
import ContactComponent from '../Components/ContactComponent/ContactComponent'

const Home = () => {
  return (
    <div>
      <Header/>
      <Banner/>
      <AboutComponent/>
      <Counter/>
      <ServiceComponent/>
      <Highlight/>
      <ProjectComponent/>
      <Highlights2/>
      <TeamComponent/>
      <ClientFeedback/>
      <ServiceProvider/>
      <FAQComponent/>
      <PricingTableComponent/>
      <BlogComponent/>
      <ContactComponent/>
      <Footer/>
    </div>
  )
}

export default Home