import React, { useEffect, useState } from "react";
import "./teamDetails.css";
import team1 from "../../images/team 1.png";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import clearity from "../../images/clarity_talk.png";
import { Link, useSearchParams } from "react-router-dom";
import { TeamData } from "./TeamData";

const TeamDetailsComponent = () => {
  const [searchParams] = useSearchParams();
  const [photo, setPhoto] = useState();
  const [sub, setSub] = useState();
  const [heading, setHeading] = useState();
  const id = searchParams.get("id")
console.log(id);
  useEffect(() => {
    const filData = TeamData.find((data) => data?.id == id)
   setPhoto(filData?.img)
   setHeading(filData?.name)
   setSub(filData?.para)
    
  }, [id]);
  
  

  return (
    <section className="troo-da-team-deatils-section" id="troo-da--deatils-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="member-deails">
              <img src={photo || team1} alt="team1" />
              <div className="team-member-detals">
                <div className="team-contact-mfo">
                  <span>Contact Information</span>
                  <ul>
                    <li>
                      <Link href="mailto:jonhathanbell@email.com">
                        jonhathanbell@email.com
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link href="">+44 123 456 7890</Link>
                    </li>
                  </ul>
                  <div className="socail-links">
                    <div className="socail-tile">Social Media</div>
                    <ul>
                      <li>
                        <Link href="#">
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <FaInstagram />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <FaTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <FaLinkedinIn />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="chat-icon">
                  <img src={clearity} alt="clearity" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="team-desc">
              <h4>{sub || "CEO & Founder"}</h4>
              <h2>{heading || "William Regle" }</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                sunt eiusmod tempor incididunt labore dolore magna aliqua enim
                minim veniam quis nostrud exercitation ullamco laboris nisi ut
                aliquip isit dx ea commodo consequat.Lorem ipsum dolor sit amet,
                consectetur adipisicing elit, sed do sunt eiusmod tempfshtor
                incididunt labore dolore magna aliqua enim minim veniam.
              </p>
              <h2>Experience & Activities</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                sunt eiusmod tempor incididunt labore dolore magna aliqua enim
                minim veniam quis nostrud exercitation ullamco laboris nisi ut
                aliquip isit dx ea commodo consequat.Lorem ipsum dolor sit amet,
                consectetur adipisicing elit, sed do sunt eiusmod tempfshtor
                incididunt labore dolore magna aliqua enim minim veniam. Lorem
                ipsum dolor sit amet, consectetur adipisicing elit, sed do sunt
                eiusmod tempor incididunt labore dolore magna aliqua enim minim
                veniam quis nostrud exercitation ullamco laboris nisi ut aliquip
                isit dx ea commodo consequat.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                sunt eiusmod tempor incididunt labore dolore magna aliqua enim
                minim veniam quis nostrud exercitation ullamco laboris nisi ut
                aliquip isit dx ea commodo consequat.Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamDetailsComponent;
