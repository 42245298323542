import client1 from "../../images/laxy-caroline.png"
import client2 from "../../images/client-2.png"
import client3 from "../../images/client-3.png"
import client4 from "../../images/client-4.png"
import client5 from "../../images/client-7.png"
import client6 from "../../images/client-8.png"
import client7 from "../../images/client-9.png"
import client8 from "../../images/client-10.png"
import { FaStar } from 'react-icons/fa';

export const ClientData = [
    {
        id : 1 ,
        name : "Laxy Caroline",
        img : client1,
        desg : "House Wife",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 2 ,
        name : "Henry classan",
        img : client2,
        desg : "Manager",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 3 ,
        name : "Hitesh Sins",
        img : client3,
        desg : "Director",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 4 ,
        name : "Laxy Caroline",
        img : client4,
        desg : "House Wife",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 5 ,
        name : "Jone Shina",
        img : client5,
        desg : "CEO, Google",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 6 ,
        name : "Henry classan",
        img : client6,
        desg : "Manager",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 7 ,
        name : "Testla Nicola",
        img : client7,
        desg : "XYZ Founder",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
    {
        id : 8 ,
        name : "Rozy Afroze",
        img : client8,
        desg : "House Wife",
        star : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>,
        para : "Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. i ma a i It has roots in a piece of classical Latin literature from."
    },
]