import workTree from "../../images/work-tree.png";
import manson from "../../images/lawn-mansion.png";
import raswell from "../../images/Roswell-roof.png";
import workRoof from "../../images/work-roof-roofer.png";
import architecture from "../../images/work-architecture-sky.png";
import roof from "../../images/Roswell-roofer-roof.png";
import service1 from "../../images/service-1.png"
import service2 from "../../images/landscape.png"
import service3 from "../../images/Roswell-roof-siding.png"
import service4 from "../../images/work-roof-roofer-men.png"
import service5 from "../../images/work-architecture-sky-roof-home.png"
import service6 from "../../images/Roswell-roofer-roof-technology.png"

export const ServiceData = [
  {
    id: 1,
    name: "Roof Renovation",
    img: workTree,
    img2 : service1,
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. ",
    para2 : "There are many variations of passages of Lorem Ipsum available, but the majority in some form.There are many variations of passages of Lorem Ipsum available, but the majority have  suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration   in some form."
  },
  {
    id: 2,
    name: "Roof Installation",
    img: manson,
    img2 : service2,
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. ",
    para2 : "There are many variations of passages of Lorem Ipsum available, but the majority in some form.There are many variations of passages of Lorem Ipsum available, but the majority have  suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration   in some form."
  },
  {
    id: 3,
    name: "Roof Survery",
    img: raswell,
    img2 : service3,
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. ",
    para2 : "There are many variations of passages of Lorem Ipsum available, but the majority in some form.There are many variations of passages of Lorem Ipsum available, but the majority have  suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration   in some form."
  },
  {
    id: 4,
    name: "Commercial Roofing",
    img: workRoof,
    img2 : service4,
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. ",
    para2 : "There are many variations of passages of Lorem Ipsum available, but the majority in some form.There are many variations of passages of Lorem Ipsum available, but the majority have  suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration   in some form."
  },
  {
    id: 5,
    name: "Residential Roofing",
    img: architecture,
    img2 : service5,
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. ",
    para2 : "There are many variations of passages of Lorem Ipsum available, but the majority in some form.There are many variations of passages of Lorem Ipsum available, but the majority have  suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration   in some form."
  },
  {
    id: 6,
    name: "Roof Repair",
    img: roof,
    img2 : service6,
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. ",
    para2 : "There are many variations of passages of Lorem Ipsum available, but the majority in some form.There are many variations of passages of Lorem Ipsum available, but the majority have  suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration   in some form."
  },
];
