import React from "react";
import "./TeamComponent.css";
import pluse from "../../images/pluse-Icon.png";
import icon from "../../images/Icon.png";
import { TeamData } from "./TeamData";
import { Link, createSearchParams } from "react-router-dom";

const TeamComponent2 = () => {
  return (
    <section className="troo-da-team-section" id="troo-da-team-section">
      <div className="container">
        <div className="row">
          {TeamData.map((e, i) => (
            <div
              className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12"
              key={i}
            >
            <Link to={`/our-team/team-details?${createSearchParams({id:e.id})}`}>
            <div className="expert-team">
                <div className="exprt-main-img">
                  <img src={e.img} alt="team1" />
                </div>
                <div className="social-icon">
                  <div className="plu">
                    <img src={pluse} alt="pluse" />
                    <ul>
                      {e.social.map((icn, i) => (
                        <li>
                          <Link to={icn.path}>{icn.icon}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="expert-dec">
                  <div className="expert-text">
                    <span>{e.para}</span>
                    <h3>{e.name}</h3>
                  </div>
                  <div className="expert-icon">
                    <img src={icon} alt="icon" />
                  </div>
                </div>
              </div>
            </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamComponent2;
