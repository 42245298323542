import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import ServiceComponent from "../../Components/ServiceComponent/ServiceComponent";
import Highlight from "../../Components/Highlights/Highlight";
import ProjectComponent from "../../Components/ProjectComponent/ProjectComponent";
import Highlights2 from "../../Components/Highlights/Highlights2";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const Services = () => {
  useDocumentTitle("Roofing | Service")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ServiceComponent />
      <Highlight/>
      <ProjectComponent/>
      <Highlights2/>
      <ContactComponent/>
      <Footer />
    </div>
  );
};

export default Services;
