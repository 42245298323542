import project1 from "../../images/project-one.png";
import project2 from "../../images/proect-two.png";
import project3 from "../../images/project-three.png";
import project4 from "../../images/project-four.png";
import project5 from "../../images/project-five.png";
import project6 from "../../images/project-sex.png";
import project7 from "../../images/project-seven.png";
import projectl1 from "../../images/building-home.png"
import projectl2 from "../../images/house-home.png"
import projectl3 from "../../images/wood-sunshine.png"
import projectl4 from "../../images/landscape-architecture-lawn.png"
import projectl5 from "../../images/architecture-structure-sky-house.png"
import projectl6 from "../../images/lawn-mansion-house-window.png"
import projectl7 from "../../images/roof-building.png"
import projectl8 from "../../images/building-home.png"

export const ProjectData = [
  {
    id: 1,
    name: "Can Be Manufactured",
    para: "Siding Corner",
    img: project1,
    img2 :projectl1
  },
  {
    id: 2,
    name: "Support Concrete Wall",
    para: "Damage Roofing",
    img: project2,
    img2 :projectl2
  },
  {
    id: 3,
    name: "Roof In Villa",
    para: "Commercial Roof",
    img: project3,
    img2 :projectl3
  },
  {
    id: 4,
    name: "Roof Designing",
    para: "Residential Roof",
    img: project4,
    img2 :projectl4
  },
  {
    id: 5,
    name: "Sterling Roof",
    para: "Industrial Roofing",
    img: project5,
    img2 :projectl5
  },
  {
    id: 6,
    name: "Minimal Design",
    para: "Industrial Roofing",
    img: project6,
    img2 :projectl6
  },
  {
    id: 7,
    name: "Kanalnal Roof",
    para: "Redesign Roofing",
    img: project7,
    img2 :projectl7
  },
  {
    id: 8,
    name: "Siding Corner",
    para: "Can Be Manufactured",
    img2 :projectl8
  },
];
