import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { HeaderData } from "../Header/HeaderData";
import { ServiceData } from "../ServiceComponent/ServiceData";
import { BlogData } from "../BlogComponent/BlogData";
import { ProjectData } from "../ProjectComponent/ProjectData";
import { TeamData } from "../TeamComponent/TeamData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("service-details")) {
        const sData = ServiceData.find((e) => e.id == id);
        setHeading(sData.name);
      }
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
      if (pathName.includes("works-details")) {
        const pData = ProjectData.find((e) => e.id == id);
        setHeading(pData.name);
      }
      if (pathName.includes("team-details")) {
        const tData = TeamData.find((e) => e.id == id);
        setHeading(tData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);

  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <section className="troo-da-about-section" id="troo-da-about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="banner-text">
              <nav aria-label="breadcrumb">
                <h1>{heading} </h1>
                <ol className="breadcrumb">
                  <Link to="/" className="breadcrumb-item">
                    Home
                  </Link>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <li
                      className={`breadcrumb-item${
                        index === breadcrumbs.length - 1 ? " current" : ""
                      } ${breadcrumb.path === "/faq" && "item-uppercase"}`}
                    >
                      <Link key={index} to={breadcrumb.path}>
                        {breadcrumb.title.replace(/-/g, " ")}
                      </Link>
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerGlobal;
