import React from "react";
import { BiPlus } from "react-icons/bi";

const ContactComponent3 = () => {
  return (
    <section className="troo-da-contact" id="troo-da-contact">
      <div className="contact-ss get-quote">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12  col-md-12 col-sm-12 col-xs-12">
              <div className="contact-text">
                <form className="row g-3 form-section">
                  <div className="col-md-6">
                    <div className="form-col">
                      <label for="inputName" className="form-label">
                        Name
                      </label>
                      <input
                        type="name"
                        className="form-control"
                        id="inputName"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-col">
                      <label for="inputPhone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="phone"
                        className="form-control"
                        id="inputphone"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-col">
                      <label for="inputEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-col">
                      <label for="inputState" className="form-label">
                        Type of Service
                      </label>
                      <select id="inputState" className="form-select">
                        <option selected>Subject...</option>
                        <option>...</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-col">
                      <label for="inputDate" className="form-label">
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputDate"
                        placeholder="29-6-2023"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-col">
                      <label for="inputTime" className="form-label">
                        Date
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="inputTime"
                        placeholder="10AM"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-col">
                      <label for="inputZip" className="form-label">
                        Your Message
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="custom-btn btn-13">
                      <BiPlus className="plus" /> Submit Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent3;
