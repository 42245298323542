import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";

const NavMenu = ({ item }) => {
  const [showcat, setShowCat] = useState(false);
  const location = useLocation();
  console.log(location);
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");
  let filt = item.subNav?.find((s) => s.subTitle === lastvalue);
  
  return (
    <>
      <li
        className="nav-item"
      >
        {!item?.subNav ? (
          <Link className={item.path === location.pathname ?"nav-link active " : "nav-link"} to={item.path}>
            {item.title}
          </Link>
        ) : (
          <Link
            onMouseEnter={() => setShowCat(true)}
            onMouseLeave={() => setShowCat(false)}
            className={item.title === lastvalue || filt ?"nav-link active " : "nav-link"}
          >
            {item.title}
            <HiOutlineChevronDown />
            {showcat && (
              <ul className={`dropdown-menu  show`}>
                {item.subNav.map((e, i) => {
                  console.log(e.subTitle);
                  return (
                    <li key={i}>
                      <Link
                        className={`${
                          e.subTitle === lastvalue
                            ? "dropdownActive dropdown-item"
                            : "dropdown-item "
                        } ${e.path === "/faq" && "item-uppercase"}`}
                        to={e.path}
                      >
                        {e.subTitle}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </Link>
        )}
      </li>
    </>
  );
};

export default NavMenu;
