import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import FAQComponent2 from "../../Components/FAQComponent/FAQComponent2";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const FAQs = () => {
  useDocumentTitle("Roofing | FAQs page")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <FAQComponent2 />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default FAQs;
