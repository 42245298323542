import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import logo from "../../images/Logo.png";
import humburger from "../../images/Humberger.png";
import Drawer from "./Drawer";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { HeaderData } from "./HeaderData";
import NavManu from "./NavManu";


const Header = () => {
  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <Navbar expand="lg">
          <Drawer />
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav ">
              {HeaderData.slice(0, 7).map((item, i) => {
                return <NavManu key={i} item={item} />;
              })}
            </ul>

            <div className="make-btn">
              {HeaderData.slice(-1).map((e, i) => {
                return (
                  <Link
                    type="button"
                    className="btn btn-lg"
                    key={i}
                    to={e.path}
                  >
                    <span>{e.title}</span>
                  </Link>
                );
              })}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <header>
        <section class="troo-da-header-section" id="troo-da-header-section">
          <div class="top-header">
            <div class="talk-link">
              <p>Need Help? Let’s Talk With Us:</p>
              <ul>
                <li>
                  <Link href="">
                    <span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5372 4.5C11.2698 4.64292 11.943 5.00119 12.4708 5.52895C12.9986 6.05671 13.3568 6.72995 13.4997 7.4625M10.5372 1.5C12.0592 1.66908 13.4784 2.35063 14.5619 3.43276C15.6454 4.51488 16.3288 5.93326 16.4997 7.455M7.66998 10.3973C6.7688 9.49612 6.05721 8.47714 5.53521 7.38992C5.49031 7.29641 5.46786 7.24965 5.45061 7.19048C5.38932 6.98021 5.43334 6.72202 5.56085 6.54395C5.59673 6.49384 5.6396 6.45097 5.72533 6.36524C5.98754 6.10303 6.11864 5.97193 6.20435 5.8401C6.5276 5.34293 6.5276 4.70199 6.20435 4.20482C6.11864 4.07299 5.98754 3.94189 5.72533 3.67968L5.57918 3.53353C5.1806 3.13495 4.98131 2.93566 4.76727 2.8274C4.3416 2.6121 3.8389 2.6121 3.41323 2.8274C3.1992 2.93566 2.99991 3.13495 2.60132 3.53353L2.4831 3.65176C2.08588 4.04897 1.88727 4.24758 1.73559 4.51761C1.56727 4.81724 1.44625 5.28261 1.44727 5.62627C1.44819 5.93598 1.50827 6.14765 1.62843 6.57098C2.27415 8.84603 3.49251 10.9928 5.28349 12.7838C7.07448 14.5748 9.22125 15.7931 11.4963 16.4389C11.9196 16.559 12.1313 16.6191 12.441 16.62C12.7847 16.621 13.25 16.5 13.5497 16.3317C13.8197 16.18 14.0183 15.9814 14.4155 15.5842L14.5337 15.466C14.9323 15.0674 15.1316 14.8681 15.2399 14.6541C15.4552 14.2284 15.4552 13.7257 15.2399 13.3C15.1316 13.086 14.9323 12.8867 14.5337 12.4881L14.3876 12.3419C14.1254 12.0797 13.9943 11.9486 13.8625 11.8629C13.3653 11.5397 12.7244 11.5397 12.2272 11.8629C12.0954 11.9486 11.9643 12.0797 11.702 12.3419C11.6163 12.4277 11.5734 12.4706 11.5233 12.5064C11.3453 12.6339 11.0871 12.678 10.8768 12.6167C10.8176 12.5994 10.7709 12.577 10.6774 12.5321C9.59014 12.0101 8.57116 11.2985 7.66998 10.3973Z"
                          stroke="#E22526"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    +44 123 456 7890{" "}
                  </Link>
                </li>

                <li>
                  <Link href="">
                    <span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.308 1.97518L15.954 5.64509C16.1535 5.77477 16.2532 5.83961 16.3255 5.9261C16.3895 6.00266 16.4375 6.09121 16.4669 6.18658C16.5 6.29431 16.5 6.41328 16.5 6.65123V12.15C16.5 13.4101 16.5 14.0402 16.2548 14.5215C16.039 14.9448 15.6948 15.2891 15.2715 15.5048C14.7902 15.75 14.1601 15.75 12.9 15.75H5.1C3.83988 15.75 3.20982 15.75 2.72852 15.5048C2.30516 15.2891 1.96095 14.9448 1.74524 14.5215C1.5 14.0402 1.5 13.4101 1.5 12.15V6.65123C1.5 6.41328 1.5 6.29431 1.53313 6.18658C1.56245 6.09121 1.61051 6.00266 1.67449 5.9261C1.74676 5.83961 1.84651 5.77477 2.04601 5.64509L7.69203 1.97519M10.308 1.97518C9.83454 1.66745 9.59782 1.51359 9.34277 1.45372C9.11732 1.4008 8.88268 1.4008 8.65723 1.45372C8.40218 1.51359 8.16546 1.66745 7.69203 1.97519M10.308 1.97518L15.7261 5.49694C15.984 5.6646 16.113 5.74843 16.1576 5.85474C16.1967 5.94765 16.1967 6.05236 16.1576 6.14526C16.113 6.25157 15.984 6.33541 15.7261 6.50307L10.308 10.0248C9.83454 10.3325 9.59782 10.4864 9.34277 10.5463C9.11732 10.5992 8.88268 10.5992 8.65723 10.5463C8.40218 10.4864 8.16546 10.3326 7.69203 10.0248L2.27395 6.50307C2.016 6.33541 1.88703 6.25157 1.84237 6.14526C1.80333 6.05236 1.80333 5.94765 1.84237 5.85474C1.88703 5.74843 2.016 5.6646 2.27395 5.49694L7.69203 1.97519"
                          stroke="#E22526"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    example@email.com
                  </Link>
                </li>
              </ul>
            </div>
            <div class="socail-links">
              <ul>
                <li>
                  <Link href="#">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link href="#">
                    <FaTwitter />
                  </Link>
                </li>
                <li>
                  <Link href="#">
                    <FaLinkedinIn />
                  </Link>
                </li>
                <li>
                  <Link href="#">
                    <FaInstagram />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <Navbar expand="lg">
              <Drawer />
              <Navbar.Brand>
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <ul className="navbar-nav ">
                  {HeaderData.slice(0, 7).map((item, i) => {
                    return <NavManu key={i} item={item} />;
                  })}
                </ul>

                <div className="make-btn">
                  {HeaderData.slice(-1).map((e, i) => {
                    return (
                      <Link
                        type="button"
                        className="btn btn-lg"
                        key={i}
                        to={e.path}
                      >
                        <span>{e.title}</span>
                      </Link>
                    );
                  })}
                </div>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
