import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import PricingTableComponent2 from '../../Components/PricingTableComponent/PricingTableComponent2'
import ContactComponent from '../../Components/ContactComponent/ContactComponent'
import useDocumentTitle from '../../PageTitle'

const PricingTable = () => {
  useDocumentTitle("Roofing | Pricing Table")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <PricingTableComponent2/>
        <ContactComponent/>

        <Footer/>
    </div>
  )
}

export default PricingTable