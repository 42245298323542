import React from 'react'
import "./ServiceProvider.css"
import whiteHome from "../../images/white-home.png"
import { BiPlus } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const ServiceProvider = () => {
  return (
    <sectio className="troo-da-services-provider" id="troo-da-services-provider">
    <div className="container">
      <div className="services-provider">
        <div className="sub-tile">
          <p><img src={whiteHome} alt='whiteHome'  />Quality Roofing Services Provider</p>
          <h2>Need Roofing Services? We Are Here For You.</h2>
          <span>Contrary to popular belief, Lorem Ipsum is not simply random text Ipsum is not It has roots.</span>
        </div>
        <div className="feed-btn"><Link href="#" className="custom-btn btn-13"><BiPlus className='plus'/>
            See All Feedback</Link></div>
      </div>
    </div>
  </sectio>
  )
}

export default ServiceProvider