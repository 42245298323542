import React, { useEffect, useState } from 'react'
import "./BlogDetails.css"
import landscape from "../../images/landscape-architecture-villa.png"
import blogdetails1 from "../../images/landscape-architecture-villa-house-interior.png"
import blogdetails2 from "../../images/landscape-architecture-villa-house-building.png"
import rightIcon from "../../images/right-icon.png"
import {
    FaTwitter,
    FaFacebookF,
    FaLinkedinIn,
    FaInstagram,
  } from "react-icons/fa";
import { BiPlus } from 'react-icons/bi'
import { FaAngleRight } from 'react-icons/fa'
import blogList1 from "../../images/blog-list1.png"
import blogList2 from "../../images/blog-list2.png"
import blogList3 from "../../images/blog-list3.png"
import blogList4 from "../../images/blog-list4.png"
import { Link, useSearchParams } from 'react-router-dom'
import Accordion from "react-bootstrap/Accordion";
import { BlogData } from './BlogData'

const BlogDetailsComponent = () => {
  const [searchParams] = useSearchParams();
  const [photo, setPhoto] = useState();
  const [heading, setHeading] = useState();
  const id = searchParams.get("id");
  console.log(id);
  useEffect(() => {
    const filData = BlogData.find((data) => data?.id == id);
    console.log(filData);
    setPhoto(filData?.img2);
    setHeading(filData?.name);
  }, [id]);
  return (
    <section className="troo-da-services-detail-section" id="troo-da-services-detail-section">
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-8 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="services-detail-section">
            <div className="auther-ss">
              <strong>By:</strong>
              <span>Williams Jow, </span>
              <strong> Date:</strong>
              <span>22 December</span>
              <strong> Categories:</strong>
              <span>Roofing, Roof Services</span>
            </div>
            <div className="services-detail-img">
              <img src={photo || landscape} alt='landscape' />
            </div>
            <div className="services-detail-text">
              <h2>{heading || "We Ensure Best Service"}</h2>
              <p>Lorem Ipsum Doller sit amet sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec
                sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend,
                ex justo aliquam nunc, in ultrices ante quam eget massa. Sed and scelerisque, odio eu tempor pulvinar,
                magna tortor finibus lorem, ut mattis tellus nunc ut quam. Curabitur quis ornare leo. Suspendisse
                bibendum nibh.</p>
              <ul>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>Emergency response time is one hour or less guaranteed.</span>
                </li>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>Until the eleifend elit is a lot of great facilities, what is the price of the pain</span>
                </li>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </li>
                <li><img src={rightIcon} alt='rightIcon' />
                  <span>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    alteration in some form, by injected humour
                  </span>
                </li>
              </ul>
              <p>Lorem Ipsum Doller sit amet sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec
                sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend,
                ex justo aliquam nunc, in ultrices ante quam eget massa. Sed and scelerisque.</p>
            </div>
          </div>
          <div className="sub-ser-img">
            <div className="row">

              <div className="col-xl-6 col-lg-6  mb-4 col-md-6 col-sm-12 col-xs-12">
                <img src={blogdetails1}  alt='blogdetails1' />
              </div>
              <div className="col-xl-6 col-lg-6  mb-4 col-md-6 col-sm-12 col-xs-12">
                <img src={blogdetails2} alt='blogdetails2'  />
              </div>
            </div>
          </div>
          <h2>What Is Included</h2>
          <div className="servci-accordiund">
          <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  5. What are the different types of asphalt shingles?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. How long will it take to replace my roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="share-artical">
            <span>Share This Articl</span>
            <div className="socail-links">
              <ul>
                <li><Link href="#"><FaFacebookF/></Link></li>
                <li><Link href="#"><FaInstagram/></Link></li>
                <li><Link href="#"><FaLinkedinIn/></Link></li>
                <li><Link href="#"><FaTwitter/></Link></li>
              </ul>
            </div>
          </div>
          <form className="row g-3 form-section blog-setails-form">
            <h4>Comments (0)</h4>
            <span>Submit a Comment</span>
            <div className="col-md-12">
              <div className="form-col">
                <label for="inputName" className="form-label">Name</label>
                <input type="name" className="form-control" id="inputName" placeholder="Name"/>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-col">
                <label for="inputEmail" className="form-label">Email</label>
                <input type="text" className="form-control" id="inputEmail" placeholder="Email"/>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-col">
                <label for="inputZip" className="form-label">Message?</label>
                <textarea className="form-control" placeholder="Message"></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-col">
                <input type="checkbox" id="checkbox1" name="checkbox" value="checkbox"/>
                <label for="checkbox" className="chek-lable"> &nbsp; Save my name, email, and website in this browser for the next
                  time I
                  comment.</label>
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="custom-btn btn-13">  <BiPlus className='plus'/> Submit
                Now</button>
            </div>
          </form>
        </div>
        <div className="col-xl-4 col-lg-4  mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="sevice-details-slidebar">
            <ul>
              <li><Link href="#">
                  <span>Single Play Roofing </span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#">
                  <span> Modified Roofing</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link></li>
              <li>
                <Link href="#">
                  <span> Built-up Roofing </span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#"> <span>Roof Inspection</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#"> <span>Roof Inspection</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
              <li><Link href="#"> <span>Metal Roofing</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link></li>
              <li><Link href="#"> <span>Other Services</span>
                  <div className="icon"><FaAngleRight/></div>
                </Link>
              </li>
            </ul>

            <div className="tag-block">
              <div className="blog-list-tiles">Tags</div>
              <ul>
                <li><Link href="#">Roofing</Link></li>
                <li><Link href="#">Commercial Roofing</Link></li>
                <li><Link href="#">Roof Tools</Link></li>
                <li><Link href="#">Home Roofing</Link></li>
                <li><Link href="#">Floor Roofs</Link></li>
                <li><Link href="#">Site Mining</Link></li>
                <li><Link href="#">Project Planning</Link></li>
                <li><Link href="#">Filteration</Link></li>
                <li><Link href="#">Constrution</Link></li>
              </ul>
            </div>
            <div className="recent-news">
              <div className="blog-list-tiles">Recent News</div>
              <div className="blog-list-section">
                <div className="blog-listes">
                  <span>
                    <div className="date-col"><span>22, Dec, 2023</span></div>
                    <img src={blogList1} alt='blogList1' />
                  </span>
                  <div className="blog-list-setila">
                    <span> Admin / Repairs, Roofing</span>
                    <h4>Keep Your Home Cool Comfortable This Summer</h4>
                    <Link href="#">Read Full Article</Link>
                  </div>
                </div>
                <div className="blog-listes">
                  <span>
                    <div className="date-col"><span>22, Dec, 2023</span></div>
                    <img src={blogList2} alt='blogList2' />
                  </span>
                  <div className="blog-list-setila">
                    <span> Admin / Repairs, Roofing</span>
                    <h4>Keep Your Home Cool Comfortable This Summer</h4>
                    <Link href="#">Read Full Article</Link>
                  </div>
                </div>
                <div className="blog-listes">
                  <span>
                    <div className="date-col"><span>22, Dec, 2023</span></div>
                    <img src={blogList3} alt='blogList3' />
                  </span>
                  <div className="blog-list-setila">
                    <span> Admin / Repairs, Roofing</span>
                    <h4>Keep Your Home Cool Comfortable This Summer</h4>
                    <Link href="#">Read Full Article</Link>
                  </div>
                </div>
                <div className="blog-listes">
                  <span>
                    <div className="date-col"><span>22, Dec, 2023</span></div>
                    <img src={blogList4} alt='blogList4' />
                  </span>
                  <div className="blog-list-setila">
                    <span> Admin / Repairs, Roofing</span>
                    <h4>Keep Your Home Cool Comfortable This Summer</h4>
                    <Link href="#">Read Full Article</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BlogDetailsComponent