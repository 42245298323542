import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import TeamDetailsComponent from '../../Components/TeamComponent/TeamDetailsComponent'
import ContactComponent from '../../Components/ContactComponent/ContactComponent'
import useDocumentTitle from '../../PageTitle'

const TeamDetails = () => {
  useDocumentTitle("Roofing | Team Details")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <TeamDetailsComponent/>
        <ContactComponent/>

        <Footer/>
    </div>
  )
}

export default TeamDetails