import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import ProjectListComponent from '../../Components/ProjectComponent/ProjectListComponent'
import ContactComponent from '../../Components/ContactComponent/ContactComponent'
import useDocumentTitle from '../../PageTitle'

const WOrkList = () => {
  useDocumentTitle("Roofing | Work Lists")
  return (
    <div>
        <Header/>
        <BannerGlobal/>
        <ProjectListComponent/>
        <ContactComponent/>
        <Footer/>
    </div>
  )
}

export default WOrkList