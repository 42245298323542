import team1 from "../../images/computer-work.png"
import team2 from "../../images/african-asian.png"
import team3 from "../../images/african-american.png"
import team4 from "../../images/team-3.png"
import team5 from "../../images/team-4.png"
import team6 from "../../images/team-5.png"
import team7 from "../../images/team-6.png"
import team8 from "../../images/team-7.png"
import team9 from "../../images/team-8.png"

import {
    FaTwitter,
    FaFacebookF,
    FaLinkedinIn,
    FaInstagram,
  } from "react-icons/fa";

export const TeamData = [
    {
        id : 1,
        name : "William Regle",
        para : "CEO & Founder",
        img : team1,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 2,
        name : "Alexa Washerman",
        para : "Project Manager",
        img : team2,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 3,
        name : "Mark Porter",
        para : "Marketing Manager",
        img : team3,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 4,
        name : "Mark Porter",
        para : "Marketing Manager",
        img : team4,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 5,
        name : "Mark Porter",
        para : "Marketing Manager",
        img : team5,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 6,
        name : "Hitech sins",
        para : "Marketing Manager",
        img : team6,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 7,
        name : "Alexa mackup",
        para : "CEO & Founder",
        img : team7,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 8,
        name : "Alexa Washerman",
        para : "Project Manager",
        img : team8,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },
    {
        id : 9,
        name : "Mark Porter",
        para : "Marketing Manager",
        img : team9,
        social : [
            {
                icon : <FaFacebookF/>,
                path : ""
            },
            {
                icon : <FaTwitter/>,
                path : ""
            },
            {
                icon : <FaLinkedinIn/>,
                path : ""
            },
            {
                icon : <FaInstagram/>,
                path : ""
            },
        ]
    },

]