import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ContactComponent from "../Components/ContactComponent/ContactComponent";
import ContactComponent3 from "../Components/ContactComponent/ContactComponent3";
import BannerGlobal from "../Components/Banner/BannerGlobal";
import useDocumentTitle from "../PageTitle";

const Quotes = () => {
  useDocumentTitle("Roofing | Quotes")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ContactComponent3 />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default Quotes;
