import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import BlogDetailsComponent from "../../Components/BlogComponent/BlogDetailsComponent";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const BlogDetails = () => {
  useDocumentTitle("Roofing | Blogs Details")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <BlogDetailsComponent />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default BlogDetails;
