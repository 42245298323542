import React, { useState } from "react";
import logo from "../../images/Logo.png";
import phone from "../../images/phone.png";
import mail from "../../images/mail.png";
import map from "../../images/map.png";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import humburger from "../../images/Humberger.png";

const Drawer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="drawer">
      <Button onClick={handleShow} className="hum-btn">
        <img src={humburger} alt="humburger" />
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="canva-logo">
            <img src={logo} alt="logoblack" />
          </div>
          <div className="about-con">
            <h5>About Us</h5>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some.
            </p>
          </div>
          <div className="socail-icon siderbar-contact">
            <h4>Contact Us</h4>
            <ul>
              <li>
                <img src={phone} alt="phone" />
                <span>
                  <a href="tel:+44 123 456 7890">+44 123 456 7890</a>
                  <a href="tel:+44 987 654 1230">+44 987 654 1230</a>
                </span>
              </li>
              <li>
                <img src={mail} alt="mail" />
                <span>
                  <a href="tel:trooyoga@email.com">trooyoga@email.com </a>
                  <a href="tel:info@trootheme.com"> info@trootheme.com</a>
                </span>
              </li>
              <li>
                <img src={map} alt="map" />
                <span>
                  8 Clarence Court,
                  <br />
                  Geraldton, 2506, Australia
                </span>
              </li>
            </ul>
          </div>
          <div className="socail-links canvas">
            <h4>Social Network</h4>
            <ul>
              <li>
                <Link href="#">
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link href="#">
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link href="#">
                  <FaLinkedinIn />
                </Link>
              </li>
              <li>
                <Link href="#">
                  <FaTwitter />
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Drawer;
