import React from "react";
import "./ComingSoonComponent.css";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import logo from "../../images/Logo.png";
import call from "../../images/footer-call-icon.png";
import { Link } from "react-router-dom";

const ComingSoonComponent = () => {
  return (
    <section className="home-newsletter">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="cooming-soon">
              <img src={logo} alt="logo" />
              <div className="com-tile">Working on</div>
              <div className="coming-sub-title">Frontend & Backend</div>
              <p>
                We Are <span>Coming Soon</span> with Our Brand{" "}
                <span>New Website</span>
              </p>

              <div className="single">
                <h2>Get notified when we launch.</h2>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                  <span className="input-group-btn">
                    <button className="btn btn-theme" type="submit">
                      NOTIFY ME
                    </button>
                  </span>
                </div>
              </div>
              <div className="socail-links">
                <ul>
                  <li>
                    <Link href="#">
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <FaInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <FaLinkedinIn />
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <FaTwitter />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="contact-details">
              <div className="call-icon">
                <img src={call} alt="call" />
              </div>
              <div className="call-text">
                <Link href="#">
                  <strong> Call Us Anytime</strong>
                  <span>+44 123 456 7890</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComingSoonComponent;
