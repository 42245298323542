import React from "react";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../PageTitle";

const Error404 = () => {
  useDocumentTitle("Roofing | Error 404")
  return (
    <section class="error-section">
      <div class="error-title">
        404
        <span>Ouch, Something Went Wrong</span>
      </div>
      <div class="er-con">
        <span>Sorry We Can’t Find The page You Are Looking For</span>
        <p>Click on Button to Back Homepage</p>
        <Link to="/" class="custom-btn btn-13">
        <BiPlus className='plus'/>See All Feedback
        </Link>
      </div>
    </section>
  );
};

export default Error404;
