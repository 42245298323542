import React from "react";
import "./ClientFeedback.css";
import star from "../../images/star-01.png";
import subtitle from "../../images/subtile-icon.png";
import thumb from "../../images/Thumbs.png";
import { BiPlus } from "react-icons/bi";
import { ClientData } from "./ClientData";
import { Link } from "react-router-dom";

const ClientFeedback = () => {
  return (
    <section className="troo-da-clients-feedback" id="troo-da-clients-feedback">
      <div className="container">
        <div className="starts">
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
          <span>
            <img src={star} alt="star" />
          </span>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="sub-tile">
              <p>
                <img src={subtitle} alt="subtitle" />
                Our Experts Team
              </p>
              <h2>
                <span>Few Words</span> From Our Customer
              </h2>
            </div>
          </div>
          <div className="col-xl-8 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="clients-text">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Lorem Ipsum is not simply random
              text.
            </div>
          </div>
        </div>
        <div className="row">
          {ClientData.map((e, i) => (
            <div
              className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12"
              key={i}
            >
              <div className="client-wapper">
                <div className="client-img">
                  <img src={e.img} alt="laxy" />
                  <div className="clientqute">
                    <p>”</p>
                  </div>
                </div>
                <div className="client-deails">
                  <div className="clit-reviw">
                    <div className="client-tile">
                      <h5>{e.name}</h5>
                      <span>{e.desg}</span>
                    </div>
                    <div className="start-img">{e.star}</div>
                  </div>
                  <p>{e.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="allexerp-bnt">
          <Link href="#" className="custom-btn btn-13">
            <BiPlus className="plus" />
            See All Feedback
          </Link>
        </div>
      </div>
      <div className="up-down">
        <img src={thumb} alt="thumb" />
      </div>
    </section>
  );
};

export default ClientFeedback;
