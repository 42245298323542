import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BannerGlobal from "../Components/Banner/BannerGlobal";
import AboutComponent from "../Components/AboutComponent/AboutComponent";
import Counter from "../Components/Counter/Counter";
import Highlight from "../Components/Highlights/Highlight";
import ProjectComponent from "../Components/ProjectComponent/ProjectComponent";
import Highlights2 from "../Components/Highlights/Highlights2";
import TeamComponent from "../Components/TeamComponent/TeamComponent";
import ClientFeedback from "../Components/ClientFeedback/ClientFeedback";
import ContactComponent from "../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../PageTitle";

const About = () => {
  useDocumentTitle("Roofing | Abouts")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <AboutComponent />
      <Counter />
      <Highlight />
      <ProjectComponent />
      <Highlights2 />
      <TeamComponent />
      <ClientFeedback />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default About;
