import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import ClientFeedback2 from "../../Components/ClientFeedback/ClientFeedback2";
import ContactComponent from "../../Components/ContactComponent/ContactComponent";
import useDocumentTitle from "../../PageTitle";

const Testimonials = () => {
  useDocumentTitle("Roofing | Testimonials")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ClientFeedback2/>
      <ContactComponent/>
      <Footer />
    </div>
  );
};

export default Testimonials;
