import React from 'react'
import "./ContactComponent.css"
import formroof from "../../images/formroof.png"
import subtitle from "../../images/subtile-icon.png"
import call from "../../images/call-icon.png"
import mail from "../../images/mail-icon.png"
import square from "../../images/squere.png"
import { Link } from 'react-router-dom'

const ContactComponent = () => {
  return (
    <section className="troo-da-form" id="troo-da-form">
    <div className="form-roof"><img src={formroof} alt='formroof' /></div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <div className="contcat-details">
            <div className="sub-tile">
              <p><img src={subtitle} alt='subtitle' />Project in Mind?</p>
              <h2>Let’s Talk About Your Project
              </h2>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical
                Latin literature from 45 BC, making it over 2000 years old.</p>
            </div>
            <div className="contact-details">
              <div className="call-icon"><img src={call} alt='call' /> </div>
              <div className="call-text"><Link href="#"> <strong> Phone</strong><span>+44 123 456 7890</span></Link> </div>
            </div>
            <div className="contact-details">
              <div className="call-icon"><img src={mail} alt='mail' /> </div>
              <div className="call-text"><Link href="#"> <strong> Email</strong><span>contact@trootemes.com</span></Link> </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
          <form className="row g-3 form-section">
            <div className="col-md-6">
              <div className="form-col">
                <label for="inputName" className="form-label">Name</label>
                <input type="name" className="form-control" id="inputName" placeholder="Name"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-col">
                <label for="inputPhone" className="form-label">Phone</label>
                <input type="phone" className="form-control" id="inputphone" placeholder="Phone"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-col">
                <label for="inputEmail" className="form-label">Email</label>
                <input type="text" className="form-control" id="inputEmail" placeholder="Email"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-col">
                <label for="inputState" className="form-label">Subject</label>
                <select id="inputState" className="form-select">
                  <option selected>Subject...</option>
                  <option>...</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-col">
                <label for="inputZip" className="form-label">How can we help you?</label>
                <textarea className="form-control" placeholder="Message"></textarea>
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="custom-btn btn-13"> <i className="fa fa-plus" aria-hidden="true"></i> Submit
                Now</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="snowflake">
      <img src={square} alt='square' />
    </div>
  </section>
  )
}

export default ContactComponent