import React from "react";
import { BlogData } from "./BlogData";
import { Link, createSearchParams } from "react-router-dom";

const BlogComponent2 = () => {
  return (
    <section className="troo-da-blog" id="troo-da-blog">
      <div className="container">
        <div className="row">
          {BlogData.map((e, i) => (
            <div
              className="col-xl-4 col-lg-4 mb-4 col-md-6 col-sm-12 col-xs-12"
              key={i}
            >
             <Link to={`/blogs/blog-details?${createSearchParams({id:e.id})}`}>
             <div className="blog-section">
                <div className="blog-img">
                  <div className="date-col">
                    <span>{e.date}</span>
                  </div>
                  <img src={e.img} alt="blog1" />
                  <div className="blog-tile">
                    <span>{e.sub}</span>
                    <h2>{e.name}</h2>
                  </div>
                </div>
              </div>
             </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogComponent2;
