import React from "react";
import "./PricingTableComponent.css";
import subtitle from "../../images/subtile-icon.png";
import check from "../../images/check.svg";
import crose from "../../images/crose.svg";
import square from "../../images/square.png";
import { BiPlus } from "react-icons/bi";
import arrow from "../../images/arrow-narrow-right.png"
import { Link } from "react-router-dom";

const PricingTableComponent = () => {
  return (
    <section className="troo-da-pricing-table" id="troo-da-pricing-table">
      <div className="container">
        <div className="sub-tile">
          <p>
            <img src={subtitle} alt="subtitle" />
            Our Pricing Table
          </p>
          <h2>No Hidden Charges. Pick Your Plan.</h2>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="pricing">
              <div className="price-cricla first-pri"></div>
              <div className="price-rupes">
                <span>$</span>
                <div className="price">
                  45<span>/ per month</span>
                </div>
              </div>
              <h2>Starter Plan</h2>
              <ul>
                <li>
                  <img src={check} alt="check" />
                  Faster Measurement
                </li>
                <li>
                  <img src={check} alt="check" />
                  Determine ground-level squares
                </li>
                <li>
                  <img src={check} alt="check" />
                  Multiply the ground-level squares
                </li>
                <li>
                  <img src={crose} alt="crose" />
                  Fibre sheet roofing designs
                </li>
                <li>
                  <img src={crose} alt="crose" />
                  Plastic Roofing Sheet Design
                </li>
              </ul>
              <div className="pric-btn">
                <Link href="#" className="custom-btn btn-13">
                  <BiPlus className="plus" />
                  See All Feedback
                </Link>
              </div>
              <div className="trign-pri">
                <img src={square} alt="square" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="pricing">
              <div className="price-cricla second-pri"></div>
              <div className="price-rupes">
                <span>$</span>
                <div className="price">
                  49<span>/ per month</span>
                </div>
              </div>
              <h2>Premium Plan</h2>
              <ul>
                <li>
                  <img src={check} alt="check" />
                  Faster Measurement
                </li>
                <li>
                  <img src={check} alt="check" />
                  Determine ground-level squares
                </li>
                <li>
                  <img src={check} alt="check" />
                  Multiply the ground-level squares
                </li>
                <li>
                  <img src={crose} alt="crose" />
                  Fibre sheet roofing designs
                </li>
                <li>
                  <img src={crose} alt="crose" />
                  Plastic Roofing Sheet Design
                </li>
              </ul>
              <div className="pric-btn">
                <Link href="#" className="custom-btn btn-13">
                  {" "}
                  <BiPlus className="plus" />
                  See All Feedback
                </Link>
              </div>
              <div className="trign-pri">
                <img src={square} alt="square" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="pricing">
              <div className="price-cricla last-pric"></div>
              <div className="price-rupes">
                <span>$</span>
                <div className="price">
                  99<span>/ per month</span>
                </div>
              </div>
              <h2>Professional Plan</h2>
              <ul>
                <li>
                  <img src={check} alt="check" />
                  Faster Measurement
                </li>
                <li>
                  <img src={check} alt="check" />
                  Determine ground-level squares
                </li>
                <li>
                  <img src={check} alt="check" />
                  Multiply the ground-level squares
                </li>
                <li>
                  <img src={crose} alt="crose" />
                  Fibre sheet roofing designs
                </li>
                <li>
                  <img src={crose} alt="crose" />
                  Plastic Roofing Sheet Design
                </li>
              </ul>
              <div className="pric-btn">
                <Link href="#" className="custom-btn btn-13">
                  {" "}
                  <BiPlus className="plus" />
                  See All Feedback
                </Link>
              </div>
              <div className="trign-pri">
                <img src={square} alt="square" />
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-plans-btn">
          <Link href="#">
            View All Pricing Plans
            <span>
              <img src={arrow} alt="arrow" />
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PricingTableComponent;
