import React from "react";
import "./Footer.css";
import call from "../../images/footer-call-icon.png";
import mail from "../../images/footer-mail-icon.png";
import map from "../../images/map-icon.png";
import footerLogo from "../../images/footer-logo.png";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import home from "../../images/footer-homr.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="troo-da-footer" id="troo-da-footer">
      <div className="container">
        <div className="row">
          <div className="footer-deails">
            <div className="contact-deials">
              <h2>Contact Us</h2>
              <div className="contact-details">
                <div className="call-icon">
                  <img src={call} alt="call" />{" "}
                </div>
                <div className="call-text">
                  <Link href="#">
                    {" "}
                    <strong> Phone</strong>
                    <span>+44 123 456 7890</span>
                  </Link>{" "}
                </div>
              </div>
              <div className="contact-details">
                <div className="call-icon">
                  <img src={mail} alt="mail" />{" "}
                </div>
                <div className="call-text">
                  <Link href="#">
                    {" "}
                    <strong> Email</strong>
                    <span>contact@trootemes.com</span>
                  </Link>{" "}
                </div>
              </div>
              <div className="contact-details">
                <div className="call-icon">
                  <img src={map} alt="map" />{" "}
                </div>
                <div className="call-text">
                  <Link href="#">
                    {" "}
                    <strong> Address</strong>
                    <span>Riverside 255,San Francisco, USA</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div className="company-deisl">
              <div className="footer-logo-section">
                <img src={footerLogo} alt="footerLogo" />
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin
                  literature from 45 BC, making it over 2000 years old Lorem
                  Ipsum.
                </p>
                <div className="socail-links">
                  <ul>
                    <li>
                      <Link href="#">
                        <FaFacebookF />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        <FaInstagram />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        <FaLinkedinIn />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        <FaTwitter />{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="copy-right">
                <p>
                  Copyright © 2022. <strong>TRoothemes</strong>. All rights
                  reserved.
                </p>
              </div>
            </div>
            <div className="contact-deials useful-link">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <Link href="#">About Us</Link>
                </li>
                <li>
                  <Link href="#">Our Works</Link>
                </li>
                <li>
                  <Link href="#">Our Blogs</Link>
                </li>
                <li>
                  <Link href="#">FAQ’s</Link>
                </li>
                <li>
                  <Link href="#">Customer Review</Link>
                </li>
                <li>
                  <Link href="#">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="contact-deials useful-link">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <Link href="#">Single Play Roofing</Link>
                </li>
                <li>
                  <Link href="#">Modified Roofing</Link>
                </li>
                <li>
                  <Link href="#">Built-up Roofing</Link>
                </li>
                <li>
                  <Link href="#">Roof Inspection</Link>
                </li>
                <li>
                  <Link href="#">Roof Installation</Link>
                </li>
                <li>
                  <Link href="#">Metal Roofing</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <ul>
            <li>
              <Link href="#">Privacy Policy</Link>
            </li>{" "}
            /
            <li>
              <Link href="#">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-slide-right">
        <img src={home} alt="home" />
      </div>
    </section>
  );
};

export default Footer;
