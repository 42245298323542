import React from 'react'
import "./ServiceComponent.css"
import subtitle from "../../images/subtile-icon.png"
import { BiPlus } from 'react-icons/bi';
import traingle from "../../images/triangle.png"
import structure from "../../images/architecture-structure.png"
import { ServiceData } from './ServiceData';
import { Link, createSearchParams } from 'react-router-dom';

const ServiceComponent = () => {
  return (
    <section className="troo-da-roofing-services" id="troo-da-roofing-services">
    <div className="container">
      <div className="cricless">
        <div className="center">
          <div id="ball"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="sub-tile">
            <p><img src={subtitle} alt='subtitle' />We Are Roofing Expert</p>
            <h2>We Provide All Types of
              <span>Roofing Services </span> for Every Need
            </h2>
          </div>
        </div>
        <div className="col-md-6">
          <div className="services-btn">
            <Link to="#" className="custom-btn btn-13"><BiPlus className='plus'/>View All Services</Link>
          </div>
        </div>
      </div>
      <div className="row">
        {
          ServiceData.map((e,i)=>
          <div className="col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12" key={i}>
          <div className="service-colum">
            <div className="number">{e.id}</div>
            <div className="services-img">
              <img src={e.img} alt='workTree' />
            </div>
            <div className="service-txt">
              <Link to={`/our-services/service-details?${createSearchParams({id:e.id})}`}>
                <div className="pluse-sign">+</div>
                <div className="serviceBlock">
                  <h3>{e.name}</h3>
                </div>
                <div className="service_hover_block">
                  <div className="pluse-sign">+</div>
                  <h3>{e.name}</h3>
                  <p>{e.para}</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
          )
        }
       
      </div>
    </div>
    <div className="triangle"><img src={traingle} alt='traingle' /></div>
    <div className="right-img">
      <div className="slide-right"><img src={structure} alt='structure' /></div>
    </div>
  </section>
  )
}

export default ServiceComponent