import React from "react";
import "./BlogList.css";
import { FaAngleRight } from "react-icons/fa";
import callIcon from "../../images/red-callIcon.png";
import { Link, createSearchParams } from "react-router-dom";
import { BlogData } from "./BlogData";

const BlogListComponent = () => {
  return (
    <section
      className="troo-da-services-detail-section"
      id="troo-da-services-detail-section"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="services-detail-section lsiting-blog">
              {BlogData.slice(0, 6).map((e, i) => (
                <div className="blog-list-section" key={i}>
                  <div className="blog-listes">
                    <span>
                      <div className="date-col">
                        <span>{e.date}</span>
                      </div>
                      <img src={e.img2} alt="blogList1" />
                    </span>
                    <div className="blog-list-setila">
                      <span>{e.sub}</span>
                      <h4>{e.name}</h4>
                      <p>{e.para}</p>
                      <Link to={`/blogs/blog-details?${createSearchParams({id:e.id})}`}>Read Full Article</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4  mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="sevice-details-slidebar">
              <div className="blogs-categories">
                <div className="blog-list-tiles">Blogs Categories</div>
                <ul>
                  <li>
                    <Link href="#">
                      <span>Single Play Roofing </span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <span> Modified Roofing</span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <span> Built-up Roofing </span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <span>Roof Inspection</span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <span>Roof Inspection</span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <span>Metal Roofing</span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <span>Other Services</span>
                      <div className="icon">
                        <FaAngleRight />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="tag-block">
                <div className="blog-list-tiles">Tags</div>
                <ul>
                  <li>
                    <Link href="#">Roofing</Link>
                  </li>
                  <li>
                    <Link href="#">Commercial Roofing</Link>
                  </li>
                  <li>
                    <Link href="#">Roof Tools</Link>
                  </li>
                  <li>
                    <Link href="#">Home Roofing</Link>
                  </li>
                  <li>
                    <Link href="#">Floor Roofs</Link>
                  </li>
                  <li>
                    <Link href="#">Site Mining</Link>
                  </li>
                  <li>
                    <Link href="#">Project Planning</Link>
                  </li>
                  <li>
                    <Link href="#">Filteration</Link>
                  </li>
                  <li>
                    <Link href="#">Constrution</Link>
                  </li>
                </ul>
              </div>
              <div className="recent-news">
                <div className="blog-list-tiles">Recent News</div>
                <div className="blog-list-section">
                  {BlogData.slice(0, 4).map((e, i) => (
                    <div className="blog-listes" key={i}>
                      <span>
                        <div className="date-col">
                          <span>{e.date}</span>
                        </div>
                        <img src={e.img} alt="blogList1" />
                      </span>
                      <div className="blog-list-setila">
                        <span> {e.sub}</span>
                        <h4>{e.name}</h4>
                        <Link to={`/blogs/blog-details?${createSearchParams({id:e.id})}`}>Read Full Article</Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="red-call">
                <div className="pulse">
                  <img src={callIcon} alt="callIcon" />
                </div>
                <div className="red-call-text">
                  <h4>Have any Questions? Call us Today!</h4>
                  <Link href="#">+44 123 456 7890</Link>
                  <Link href="#">+44 987 654 3210</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogListComponent;
