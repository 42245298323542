import React from "react";
import "./FAQComponent.css";
import subtitle from "../../images/subtile-icon.png";
import chart from "../../images/chat-circle.png";
import question from "../../images/questions-mark.png";
import Accordion from "react-bootstrap/Accordion";

const FAQComponent = () => {
  return (
    <section className="troo-da-faq" id="troo-da-faq">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <div className="chat-ss">
              <div className="chat-col">
                <div className="sub-tile">
                  <p>
                    <img src={subtitle} alt="subtitle" />
                    Our Experts Team
                  </p>
                  <h2>Have Any Questions? Find Answers Here</h2>
                  <span>
                    Didn’t Find Your Answer Here? Just Send Us a Message or Call
                    Us and We’ll Help In No Time.
                  </span>
                </div>
                <div className="chat-call">
                  <div className="chat-img">
                    <img src={chart} alt="chart" />
                  </div>
                  <div className="chat-call">
                    <span>+44 123 456 7890 contact@troothemes.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 mb-4 col-md-6 col-sm-12 col-xs-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. How do I know if I need a new roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. What are the signs my roof has problems?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. I have a roof leak, what do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  4. Do new roofs have warranties?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  5. What are the different types of asphalt shingles?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. How long will it take to replace my roof?
                </Accordion.Header>
                <Accordion.Body>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin literature
                  from 45 BC, making it over 2000 years old. Lorem Ipsum is not
                  simply random text.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="vert-move">
          <img src={question} alt="question" />
        </div>
      </div>
    </section>
  );
};

export default FAQComponent;
