import React, { useState } from "react";
import "./Counter.css";
import roof from "../../images/reshot-icon-roof.png";
import worker from "../../images/reshot-icon-worker.png";
import award from "../../images/reshot-icon-awards.png";
import happy from "../../images/reshot-icon-happy.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Counter = () => {
  const [counter, setCounter] = useState(false);
  return (
    <section className="troo-da-counter-section" id="troo-da-counter-section">
      <div className="counder-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 mb-3 col-md-6 col-sm-12 col-xs-12">
              <div className="counter-box">
                <div className="conter-icon">
                  <img src={roof} alt="roof" />
                </div>
                <div className="conter-tx">
                  <ScrollTrigger
                    onEnter={() => setCounter(true)}
                    onExit={() => setCounter(false)}
                    className="counter"
                  >
                    {counter && (
                      <CountUp start={0} end={22} duration={3} delay={0} />
                    )}
                    <strong>+</strong>
                  </ScrollTrigger>
                </div>
              </div>
              <div className="conter-tile">Projects Completed</div>
            </div>
            <div className="col-xl-3 col-lg-6 mb-3 col-md-6 col-sm-12 col-xs-12">
              <div className="counter-box">
                <div className="conter-icon">
                  <img src={worker} alt="worker" />
                </div>
                <div className="conter-tx">
                  <ScrollTrigger
                    onEnter={() => setCounter(true)}
                    onExit={() => setCounter(false)}
                    className="counter"
                  >
                    {counter && (
                      <CountUp start={2} end={43} duration={3} delay={0} />
                    )}
                    <strong>+</strong>
                  </ScrollTrigger>
                </div>
              </div>
              <div className="conter-tile">Expert Workers</div>
            </div>
            <div className="col-xl-3 col-lg-6 mb-3 col-md-6 col-sm-12 col-xs-12">
              <div className="counter-box">
                <div className="conter-icon">
                  <img src={award} alt="award" />
                </div>
                <div className="conter-tx">
                  <ScrollTrigger
                    onEnter={() => setCounter(true)}
                    onExit={() => setCounter(false)}
                    className="counter"
                  >
                    {counter && (
                      <CountUp start={11} end={52} duration={3} delay={0} />
                    )}
                    <strong>+</strong>
                  </ScrollTrigger>
                </div>
              </div>
              <div className="conter-tile">Awards Recieved</div>
            </div>
            <div className="col-xl-3 col-lg-6 mb-3 col-md-6 col-sm-12 col-xs-12">
              <div className="counter-box">
                <div className="conter-icon">
                  <img src={happy} alt="happy" />
                </div>
                <div className="conter-tx">
                  <ScrollTrigger
                    onEnter={() => setCounter(true)}
                    onExit={() => setCounter(false)}
                    className="counter"
                  >
                    {counter && (
                      <CountUp start={0} end={10} duration={3} delay={0} />
                    )}
                    <strong>+</strong>
                  </ScrollTrigger>
                </div>
              </div>
              <div className="conter-tile">Happy Customers</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counter;
